import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaAlert: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 5.76668C12.7292 5.76668 12.9167 5.57918 12.9167 5.35001V3.11668C12.9167 2.88751 12.7292 2.70001 12.5 2.70001C12.2709 2.70001 12.0834 2.88751 12.0834 3.11668V5.35001C12.0834 5.57918 12.2709 5.76668 12.5 5.76668ZM6.71254 7.92085C6.79587 8.00418 6.90004 8.04168 7.00837 8.04168C7.11671 8.04168 7.22087 8.00001 7.30421 7.92085C7.46671 7.75835 7.46671 7.49585 7.30421 7.33335L5.72504 5.75418C5.56254 5.59168 5.30004 5.59168 5.13754 5.75418C4.97504 5.91668 4.97504 6.17918 5.13754 6.34168L6.71254 7.92085ZM5.14587 13.1167C5.14587 12.8875 4.95837 12.7 4.72921 12.7H2.50004C2.27087 12.7 2.08337 12.8875 2.08337 13.1167C2.08337 13.3458 2.27087 13.5333 2.50004 13.5333H4.72921C4.96254 13.5333 5.14587 13.3458 5.14587 13.1167ZM17.9917 8.04168C18.1 8.04168 18.2042 8.00001 18.2875 7.92085L19.8667 6.34168C20.0292 6.17918 20.0292 5.91668 19.8667 5.75418C19.7042 5.59168 19.4417 5.59168 19.2792 5.75418L17.7 7.33335C17.5375 7.49585 17.5375 7.75835 17.7 7.92085C17.7792 8.00001 17.8875 8.04168 17.9917 8.04168ZM22.5 12.7H20.2709C20.0417 12.7 19.8542 12.8875 19.8542 13.1167C19.8542 13.3458 20.0417 13.5333 20.2709 13.5333H22.5C22.7292 13.5333 22.9167 13.3458 22.9167 13.1167C22.9167 12.8875 22.7292 12.7 22.5 12.7Z"
        fill="#9FA7B3"
      />
      <path
        d="M20.8542 18.4916H19.6834V14.0916C19.6834 10.1333 16.4584 6.91248 12.5001 6.91248C8.54175 6.91248 5.31675 10.1333 5.31675 14.0916V18.4916H4.14591C3.91675 18.4916 3.72925 18.6791 3.72925 18.9083V21.8833C3.72925 22.1125 3.91675 22.3 4.14591 22.3H20.8542C21.0834 22.3 21.2709 22.1125 21.2709 21.8833V18.9083C21.2709 18.6791 21.0834 18.4916 20.8542 18.4916ZM15.0042 9.63331C15.1334 9.44164 15.3917 9.39164 15.5834 9.52081C17.1042 10.55 18.0167 12.2583 18.0167 14.0916C18.0167 14.3208 17.8292 14.5083 17.6001 14.5083C17.3667 14.5083 17.1834 14.3208 17.1834 14.0916C17.1834 12.5375 16.4084 11.0875 15.1167 10.2125C14.9251 10.0833 14.8751 9.82498 15.0042 9.63331ZM12.5001 8.57914C13.0251 8.57914 13.5417 8.64998 14.0417 8.79581C14.2626 8.86248 14.3876 9.09164 14.3251 9.31248C14.2709 9.49581 14.1042 9.61248 13.9251 9.61248C13.8876 9.61248 13.8459 9.60831 13.8084 9.59581C13.3834 9.47498 12.9459 9.41248 12.5001 9.41248C12.2709 9.41248 12.0834 9.22498 12.0834 8.99581C12.0834 8.76248 12.2709 8.57914 12.5001 8.57914Z"
        fill="#9FA7B3"
      />
    </svg>
  );
};
