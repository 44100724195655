import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaDisk: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 5.5H6.1875V2.0625H11V5.5ZM19.7354 7.83241L14.1419 2.26256C14.0129 2.13441 13.8385 2.06249 13.6567 2.0625H12.375V6.1875C12.375 6.36984 12.3026 6.5447 12.1736 6.67364C12.0447 6.80257 11.8698 6.875 11.6875 6.875H5.5C5.31766 6.875 5.1428 6.80257 5.01386 6.67364C4.88493 6.5447 4.8125 6.36984 4.8125 6.1875V2.0625H4.125C3.57818 2.0631 3.05392 2.28059 2.66726 2.66726C2.28059 3.05392 2.0631 3.57818 2.0625 4.125V17.875C2.0631 18.4218 2.28059 18.9461 2.66726 19.3327C3.05392 19.7194 3.57818 19.9369 4.125 19.9375H4.8125V12.375C4.8125 12.1927 4.88493 12.0178 5.01386 11.8889C5.1428 11.7599 5.31766 11.6875 5.5 11.6875H16.5C16.6823 11.6875 16.8572 11.7599 16.9861 11.8889C17.1151 12.0178 17.1875 12.1927 17.1875 12.375V19.9375H17.875C18.4218 19.9369 18.9461 19.7194 19.3327 19.3327C19.7194 18.9461 19.9369 18.4218 19.9375 17.875V8.31985C19.9375 8.22931 19.9196 8.13967 19.885 8.05603C19.8503 7.9724 19.7995 7.89641 19.7354 7.83241ZM6.1875 19.9375H8.25V15.125C8.25 14.9427 8.32243 14.7678 8.45136 14.6389C8.5803 14.5099 8.75516 14.4375 8.9375 14.4375C9.11984 14.4375 9.2947 14.5099 9.42364 14.6389C9.55257 14.7678 9.625 14.9427 9.625 15.125V19.9375H12.375V15.125C12.375 14.9427 12.4474 14.7678 12.5764 14.6389C12.7053 14.5099 12.8802 14.4375 13.0625 14.4375C13.2448 14.4375 13.4197 14.5099 13.5486 14.6389C13.6776 14.7678 13.75 14.9427 13.75 15.125V19.9375H15.8125V13.0625H6.1875V19.9375Z"
        fill="#9FA7B3"
      />
    </svg>
  );
};
