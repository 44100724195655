import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaSummary: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3092 15.7872V19.5319C15.3092 19.7872 15.5224 20 15.7783 20H19.5309C19.7868 20 20 19.7872 20 19.5319V15.7872C20 15.5319 19.7868 15.3191 19.5309 15.3191H15.7783C15.5224 15.2766 15.3092 15.4894 15.3092 15.7872ZM0.469083 20H4.22175C4.47761 20 4.69083 19.7872 4.69083 19.5319V15.7872C4.69083 15.5319 4.47761 15.3191 4.22175 15.3191H0.469083C0.21322 15.3191 0 15.5319 0 15.7872V19.5319C0 19.7872 0.21322 20 0.469083 20ZM18.1237 12.5532H10.4478V11.0213C10.3198 11.0213 10.1493 11.0638 10.0213 11.0638C9.89339 11.0638 9.72281 11.0638 9.59488 11.0213V12.5532H1.91898V14.4255H2.77185V13.4043H17.2708V14.4255H18.1237V12.5532ZM10.0213 0C7.20682 0 4.90405 2.29787 4.90405 5.10638C4.90405 7.91489 7.20682 10.2128 10.0213 10.2128C12.8358 10.2128 15.1386 7.91489 15.1386 5.10638C15.1386 2.29787 12.8358 0 10.0213 0ZM9.59488 8.97872H9.08316V5.53192L7.59062 5.95745L7.33475 5.14894L9.04051 4.6383V3.74468L7.54797 4.17021L7.29211 3.3617L8.99787 2.85106V1.53191H9.85075V2.59574L11.9403 2L12.1962 2.80851L9.89339 3.48936V4.38298L11.9829 3.78723L12.2388 4.59575L9.93603 5.2766V8.08511C11.2154 7.91489 12.1962 6.85106 12.1962 5.53192H13.049C13.0064 7.44681 11.4712 8.97872 9.59488 8.97872Z"
        fill="#9FA7B3"
      />
      <path
        d="M9.59488 8.97872H9.08316V5.53192L7.59062 5.95745L7.33475 5.14894L9.04051 4.6383V3.74468L7.54797 4.17021L7.29211 3.3617L8.99787 2.85106V1.53191H9.85075V2.59574L11.9403 2L12.1962 2.80851L9.89339 3.48936V4.38298L11.9829 3.78723L12.2388 4.59575L9.93603 5.2766V8.08511C11.2154 7.91489 12.1962 6.85106 12.1962 5.53192H13.049C13.0064 7.44681 11.4712 8.97872 9.59488 8.97872Z"
        fill="#9FA7B3"
      />
    </svg>
  );
};
