import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaCluster: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.11111 17.5556C1.44444 17.5556 1 17.1111 1 16.4444V7.55556C1 6.88889 1.44444 6.44444 2.11111 6.44444C2.77778 6.44444 3.22222 6.88889 3.22222 7.55556V16.4444C3.22222 17.1111 2.77778 17.5556 2.11111 17.5556ZM19.8889 17.5556C19.2222 17.5556 18.7778 17.1111 18.7778 16.4444V7.55556C18.7778 6.88889 19.2222 6.44444 19.8889 6.44444C20.5556 6.44444 21 6.88889 21 7.55556V16.4444C21 17.1111 20.5556 17.5556 19.8889 17.5556ZM11 22C10.3333 22 9.88889 21.5556 9.88889 20.8889V3.11111C9.88889 2.44444 10.3333 2 11 2C11.6667 2 12.1111 2.44444 12.1111 3.11111V20.8889C12.1111 21.5556 11.6667 22 11 22ZM15.4444 19.7778C14.7778 19.7778 14.3333 19.3333 14.3333 18.6667V5.33333C14.3333 4.66667 14.7778 4.22222 15.4444 4.22222C16.1111 4.22222 16.5556 4.66667 16.5556 5.33333V18.6667C16.5556 19.3333 16.1111 19.7778 15.4444 19.7778ZM6.55556 19.7778C5.88889 19.7778 5.44444 19.3333 5.44444 18.6667V5.33333C5.44444 4.66667 5.88889 4.22222 6.55556 4.22222C7.22222 4.22222 7.66667 4.66667 7.66667 5.33333V18.6667C7.66667 19.3333 7.22222 19.7778 6.55556 19.7778Z"
        fill="#9FA7B3"
      />
    </svg>
  );
};
