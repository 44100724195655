import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaAnalytics: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 23H2C1.73478 23 1.48043 22.8946 1.29289 22.7071C1.10536 22.5196 1 22.2652 1 22V12C1 11.7348 1.10536 11.4804 1.29289 11.2929C1.48043 11.1054 1.73478 11 2 11H6C6.26522 11 6.51957 11.1054 6.70711 11.2929C6.89464 11.4804 7 11.7348 7 12V22C7 22.2652 6.89464 22.5196 6.70711 22.7071C6.51957 22.8946 6.26522 23 6 23ZM3 21H5V13H3V21ZM14 23H10C9.73478 23 9.48043 22.8946 9.29289 22.7071C9.10536 22.5196 9 22.2652 9 22V2C9 1.73478 9.10536 1.48043 9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H14C14.2652 1 14.5196 1.10536 14.7071 1.29289C14.8946 1.48043 15 1.73478 15 2V22C15 22.2652 14.8946 22.5196 14.7071 22.7071C14.5196 22.8946 14.2652 23 14 23ZM11 21H13V3H11V21ZM22 23H18C17.7348 23 17.4804 22.8946 17.2929 22.7071C17.1054 22.5196 17 22.2652 17 22V5C17 4.73478 17.1054 4.48043 17.2929 4.29289C17.4804 4.10536 17.7348 4 18 4H22C22.2652 4 22.5196 4.10536 22.7071 4.29289C22.8946 4.48043 23 4.73478 23 5V22C23 22.2652 22.8946 22.5196 22.7071 22.7071C22.5196 22.8946 22.2652 23 22 23ZM19 21H21V6H19V21Z"
        fill="#999999"
      />
    </svg>
  );
};
