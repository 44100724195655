import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaProcess: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8125 4.6875C3.74132 4.6875 4.51033 4.01144 4.65938 3.125H8.3081L7.90405 3.52905L8.34594 3.97095L9.50439 2.8125L8.34595 1.65405L7.90405 2.09595L8.30811 2.5H4.65939C4.51032 1.61356 3.74132 0.9375 2.8125 0.9375C1.77696 0.9375 0.9375 1.77696 0.9375 2.8125C0.9375 3.84804 1.77696 4.6875 2.8125 4.6875ZM15.4688 2.5H13.4094C13.2603 1.61356 12.4913 0.9375 11.5625 0.9375C10.527 0.9375 9.6875 1.77696 9.6875 2.8125C9.6875 3.84804 10.527 4.6875 11.5625 4.6875C12.4913 4.6875 13.2603 4.01144 13.4094 3.125H15.4688C17.2781 3.125 18.75 4.59702 18.75 6.40625C18.75 8.21548 17.2781 9.6875 15.4688 9.6875H10.4419L10.8459 9.28345L10.4041 8.84155L9.24561 10L10.4041 11.1584L10.8459 10.7166L10.4419 10.3125H15.4688C17.6227 10.3125 19.375 8.56018 19.375 6.40625C19.375 4.25232 17.6227 2.5 15.4688 2.5Z"
        fill="#9FA7B3"
      />
      <path
        d="M17.1875 19.0625C18.223 19.0625 19.0625 18.223 19.0625 17.1875C19.0625 16.152 18.223 15.3125 17.1875 15.3125C16.152 15.3125 15.3125 16.152 15.3125 17.1875C15.3125 18.223 16.152 19.0625 17.1875 19.0625Z"
        fill="#9FA7B3"
      />
      <path
        d="M8.4375 15.3125C7.40196 15.3125 6.5625 16.152 6.5625 17.1875C6.5625 18.223 7.40196 19.0625 8.4375 19.0625C9.36632 19.0625 10.1353 18.3864 10.2844 17.5H13.9331L13.5291 17.9041L13.9709 18.3459L15.1294 17.1875L13.9709 16.0291L13.5291 16.4709L13.9331 16.875H10.2844C10.1353 15.9886 9.36632 15.3125 8.4375 15.3125Z"
        fill="#9FA7B3"
      />
      <path
        d="M4.53125 17.5H5.18311L4.77905 17.9041L5.22095 18.3459L6.37939 17.1875L5.22095 16.0291L4.77905 16.4709L5.18311 16.875H4.53125C2.72186 16.875 1.25 15.403 1.25 13.5938C1.25 11.7845 2.72186 10.3125 4.53125 10.3125H5.34062C5.48968 11.1989 6.25868 11.875 7.1875 11.875C8.22304 11.875 9.0625 11.0355 9.0625 10C9.0625 8.96446 8.22304 8.125 7.1875 8.125C6.25868 8.125 5.48968 8.80106 5.34062 9.6875H4.53125C2.37732 9.6875 0.625 11.4398 0.625 13.5938C0.625 15.7477 2.37732 17.5 4.53125 17.5Z"
        fill="#9FA7B3"
      />
    </svg>
  );
};
