import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaNetwork: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 17.5759C10.6425 17.5759 10.3538 17.2872 10.3538 16.9297V14.5028C10.3538 14.1453 10.6425 13.8566 11 13.8566C11.3575 13.8566 11.6463 14.1453 11.6463 14.5028V16.9297C11.6463 17.2872 11.3575 17.5759 11 17.5759Z"
        fill="#9FA7B3"
      />
      <path
        d="M15.2384 20.0509C14.8809 20.0509 14.5921 19.7622 14.5921 19.4047V18.5487H11.9728C11.6153 18.5487 11.3265 18.26 11.3265 17.9025C11.3265 17.545 11.6153 17.2562 11.9728 17.2562H15.2384C15.5959 17.2562 15.8846 17.545 15.8846 17.9025V19.4012C15.8846 19.7622 15.5959 20.0509 15.2384 20.0509ZM6.75807 20.0509C6.40057 20.0509 6.11182 19.7622 6.11182 19.4047V17.9025C6.11182 17.545 6.40057 17.2562 6.75807 17.2562H10.0237C10.3812 17.2562 10.6699 17.545 10.6699 17.9025C10.6699 18.26 10.3812 18.5487 10.0237 18.5487H7.40775V19.4012C7.40432 19.7622 7.11557 20.0509 6.75807 20.0509Z"
        fill="#9FA7B3"
      />
      <path
        d="M15.2385 22C14.3447 22 13.616 21.2712 13.616 20.3775C13.616 19.4837 14.3447 18.755 15.2385 18.755C16.1322 18.755 16.861 19.4837 16.861 20.3775C16.861 21.2712 16.1322 22 15.2385 22ZM15.2385 20.0509C15.0597 20.0509 14.9119 20.1987 14.9119 20.3775C14.9119 20.5562 15.0597 20.7041 15.2385 20.7041C15.4172 20.7041 15.5651 20.5562 15.5651 20.3775C15.5651 20.1987 15.4207 20.0509 15.2385 20.0509ZM6.76162 22C5.86787 22 5.13912 21.2712 5.13912 20.3775C5.13912 19.4837 5.86787 18.755 6.76162 18.755C7.65537 18.755 8.38412 19.4837 8.38412 20.3775C8.38068 21.2712 7.65537 22 6.76162 22ZM6.76162 20.0509C6.58287 20.0509 6.43506 20.1987 6.43506 20.3775C6.43506 20.5562 6.58287 20.7041 6.76162 20.7041C6.94037 20.7041 7.08818 20.5562 7.08818 20.3775C7.08818 20.1987 6.94037 20.0509 6.76162 20.0509ZM10.9966 19.525C10.1029 19.525 9.37412 18.7963 9.37412 17.9025C9.37412 17.0088 10.1029 16.28 10.9966 16.28C11.8938 16.2834 12.6191 17.0122 12.6191 17.9025C12.6191 18.7963 11.8904 19.525 10.9966 19.525ZM10.9966 17.5759C10.8179 17.5759 10.6701 17.7238 10.6701 17.9025C10.6701 18.0813 10.8179 18.2291 10.9966 18.2291C11.1754 18.2291 11.3232 18.0813 11.3232 17.9025C11.3232 17.7238 11.1788 17.5759 10.9966 17.5759ZM11.0001 15.1491C6.8235 15.1491 3.42725 11.7494 3.42725 7.57281C3.42725 3.39625 6.8235 0 11.0001 0C15.1766 0 18.5763 3.39969 18.5763 7.57625C18.5763 11.7528 15.1766 15.1491 11.0001 15.1491ZM11.0001 1.2925C7.5385 1.2925 4.71975 4.11125 4.71975 7.57281C4.71975 11.0344 7.5385 13.8566 11.0001 13.8566C14.4616 13.8566 17.2804 11.0378 17.2804 7.57625C17.2804 4.11469 14.4616 1.2925 11.0001 1.2925Z"
        fill="#9FA7B3"
      />
      <path
        d="M11 15.1491C10.6425 15.1491 10.3538 14.8603 10.3538 14.5028V0.64625C10.3538 0.28875 10.6425 0 11 0C11.3575 0 11.6463 0.28875 11.6463 0.64625V14.5028C11.6463 14.8603 11.3575 15.1491 11 15.1491Z"
        fill="#9FA7B3"
      />
      <path
        d="M11 15.1491C8.76567 15.1491 7.0813 11.8938 7.0813 7.57281C7.0813 3.25188 8.76567 0 11 0C13.2344 0 14.9188 3.25531 14.9188 7.57625C14.9188 11.8972 13.2344 15.1491 11 15.1491ZM11 1.2925C9.75911 1.2925 8.3738 3.87063 8.3738 7.57281C8.3738 11.275 9.75911 13.8566 11 13.8566C12.241 13.8566 13.6263 11.2784 13.6263 7.57625C13.6263 3.87406 12.241 1.2925 11 1.2925Z"
        fill="#9FA7B3"
      />
      <path
        d="M16.9057 11.299H5.09443C4.73693 11.299 4.44818 11.0103 4.44818 10.6528C4.44818 10.2953 4.73693 10.0065 5.09443 10.0065H16.9057C17.2632 10.0065 17.5519 10.2953 17.5519 10.6528C17.5519 11.0103 17.2632 11.299 16.9057 11.299ZM17.9266 8.22246H4.0735C3.716 8.22246 3.42725 7.93371 3.42725 7.57621C3.42725 7.21871 3.716 6.92996 4.0735 6.92996H17.9301C18.2876 6.92996 18.5763 7.21871 18.5763 7.57621C18.5763 7.93027 18.2841 8.22246 17.9266 8.22246ZM16.9022 5.14589H5.09443C4.73693 5.14589 4.44818 4.85714 4.44818 4.49964C4.44818 4.14214 4.73693 3.85339 5.09443 3.85339H16.9022C17.2597 3.85339 17.5485 4.14214 17.5485 4.49964C17.5485 4.85714 17.2597 5.14589 16.9022 5.14589Z"
        fill="#9FA7B3"
      />
    </svg>
  );
};
