import React, { FunctionComponent } from 'react';
import { SvgProps } from '../types';

export const PerconaDatabase: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} {...rest} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.6088 13.2284C18.4045 14.7773 14.5588 15.9114 10.0002 15.9114C5.44167 15.9114 1.59596 14.7773 0.390244 13.2284C0.135958 12.8989 0.000244141 13.075 0.000244141 13.2364V15.5114C0.000244141 17.7159 4.47739 20 10.0002 20C15.5231 20 20.0002 17.7159 20.0002 15.5125V13.2375C20.0002 13.075 19.8631 12.8989 19.6088 13.2284ZM19.6245 7.41477C18.4388 8.78409 14.5788 9.78977 10.0002 9.78977C5.42167 9.78977 1.56024 8.78409 0.37453 7.41477C0.130244 7.13295 0.000244141 7.28636 0.000244141 7.4125V10.0909C0.000244141 12.0932 4.47739 13.7148 10.0002 13.7148C15.5231 13.7148 20.0002 12.092 20.0002 10.0909V7.4125C20.0002 7.28636 19.8688 7.13295 19.6245 7.41477ZM10.0002 0C4.47739 0 0.000244141 1.34091 0.000244141 2.99205V4.42386C0.000244141 6.175 4.47739 7.59545 10.0002 7.59545C15.5231 7.59545 20.0002 6.175 20.0002 4.42386V2.99205C20.0002 1.34091 15.5231 0 10.0002 0Z"
        fill="#9FA7B3"
      />
    </svg>
  );
};
